/*------- 5. Section title style  -------*/

.section-title {
  border-bottom: 1.13px solid #878787;
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 30px;
    }
  }
  h2 {
    font-size: 42.39px;
    color: $theme-color;
    font-weight: 600;
    margin: 0 0 0;
    position: relative;
    display: inline-block;
    @media #{$xs-layout} {
      font-size: 24px;
      line-height: 30px;
      &.mb-50 {
        margin-bottom: 30px;
      }
    }
  }
  &.mb-55 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  &.no-border {
    h2 {
      font-size: 36px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
